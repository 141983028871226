// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photo-stories-birlanagar-js": () => import("./../../../src/pages/photo-stories/birlanagar.js" /* webpackChunkName: "component---src-pages-photo-stories-birlanagar-js" */),
  "component---src-pages-photo-stories-index-js": () => import("./../../../src/pages/photo-stories/index.js" /* webpackChunkName: "component---src-pages-photo-stories-index-js" */),
  "component---src-pages-photo-stories-musahar-js": () => import("./../../../src/pages/photo-stories/musahar.js" /* webpackChunkName: "component---src-pages-photo-stories-musahar-js" */),
  "component---src-pages-travel-and-street-index-js": () => import("./../../../src/pages/travel-and-street/index.js" /* webpackChunkName: "component---src-pages-travel-and-street-index-js" */),
  "component---src-pages-wedding-index-js": () => import("./../../../src/pages/wedding/index.js" /* webpackChunkName: "component---src-pages-wedding-index-js" */)
}

